.application {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 100px 0px;
}

.application__headline {
    font-size: var(--size-small-heading);
    font-family: var(--font-heading);
    text-align: center;
    padding: 25px 0px;
    width: 80%;
}

.application__wrapper {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.application__media {
    width: 47%;
}

.application__img {
    min-width: 100%;
    max-width: 100%;
    height: 550px;
    object-fit: cover;
}

.form {
    width: 100%;
    height: auto;
}

.form__wrapper {
    width: 47%;
    padding-top: 20px;
}

.form input {
    margin-bottom: 20px;
}

.form select {
    margin-bottom: 20px;
}

.form textarea {
    padding-bottom: 7px;
    margin-bottom: 20px;
}

:root {
    --color-light: white;
    --color-dark: #212121;
    --color-signal: #fab700;
    --size-bezel: .5rem;
    --size-radius: 4px;
}

.input {
    position: relative;
    margin-bottom: 15px;
    display: flex;
}

.input__label {
    position: absolute;
    left: 0px;
    top: 5px;
    margin: auto;
    white-space: nowrap;
    transform: translate(0, 0);
    transform-origin: 0 0;
    background: transparent;
    transition: transform 120ms ease-in;
    font-family: var(--font-heading);
    line-height: 1.2;
    color: black;
}

.input__field {
    box-sizing: border-box;
    display: block;
    width: 100%;
    border: 2px solid var(--green-light);
    color: black;
    background: transparent;
    border-radius: var(--size-radius);
    padding: 5px 10px;
    height: 40px;
}

.input__field--textarea {
    box-sizing: border-box;
    display: block;
    width: 100%;
    border: 2px solid var(--green-light);
    color: black;
    background: transparent;
    border-radius: var(--size-radius);
    padding: 5px 10px;
    height: 150px;
}

.input__field:focus + .input__label, .input__label {
    transform: translate(0, -140%) scale(.9);
    color: black;
    font-weight: bolder;
}

input[type="file"] {
    padding: 5px 10px;
    margin: 0;
    border: none;
    position: absolute;
    top: 4px;
    left: 32px;
    overflow-x: fragments;
    width: 90%;
}

.custom-file-upload {
    box-sizing: border-box;
    width: 100%;
    border: 2px solid var(--green-light);
    color: black;
    background: transparent;
    border-radius: var(--size-radius);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0px;
    height: 40px;
    margin-bottom: 40px;
}

.input__label-file {
    color: white;
    background-color: var(--green-main);;
    padding: 7px 52px 11px 50px;
    height: 100%;
    position: absolute;
    font-family: var(--font-accent);
}

.input__label-file:hover {
    transform: scale(1.1);
    transition: all .7s ease;
}

.input__label-checkbox {
    box-sizing: border-box;
    display: block;
    color: black;
    background: transparent;
    height: 40px;
    padding: 10px;
    font-size: var(--size-small);
    font-family: var(--font-text);
}

.input__field-checkbox {
    box-sizing: border-box;
    display: block;
    width: 20px;
    border: 2px solid var(--green-light);
    color: black;
    background: transparent;
    border-radius: var(--size-radius);
    padding: 5px 10px;
    height: 40px;
}

input[type="checkbox"] {
    margin: 0;
    padding: 11px;
}

input[type="submit"] {
    margin: auto;
    width: 100%;
    height: 45px;
    background-color: var(--green-main);
    border: 2px solid var(--green-main);
    border-radius: var(--size-radius);
    color: white;
    font-size: var(--size-small);
    cursor: pointer;
    transition: all .7s ease-in-out;
    font-family: var(--font-accent);
}

input[type="submit"]:hover {
    transform: scale(1.1);
}

input[type="submit"]:disabled {
    background-color: lightgrey;
}
