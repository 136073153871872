.impression {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.impression__heading {
    font-size: var(--size-small-heading);
    font-family: var(--font-heading);
    text-align: center;
    padding: 25px 0px 25px 0px;
}

.impression__wrapper {
    width: 80% !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto !important;
    overflow: hidden;
}

.impression__content {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
}

.impression__single {
    width: 27%;
    position: relative;
    cursor: pointer;
}

.impression__media {
    min-width: 100%;
    max-width: 100%;
    min-height: 400px;
    max-height: 400px;
    object-fit: cover;
}

.impression__logo {
    height: 60px;
    position: absolute;
    top: +45%;
    left: +45%;
    transition: all .5s ease-in-out;
    pointer-events: none;
}

.impression__text {
    position: relative;
    font-size: var(--size-text);
    font-family: var(--font-heading);
    color: var(--white);
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 45px 0px 15px 0px;
    background: linear-gradient(to bottom, transparent,transparent 20%, black 75%);
    transition: all .5s ease-in-out;
    display: block;
}

.impression__text::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 8px;
    border-radius: 0px;
    background-color: var(--green-light);
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .5s ease-in-out;
}

.impression__single:hover .impression__text {
    color: var(--green-light);
}

.impression__single:hover .impression__text::before {
    transform-origin: left;
    transform: scaleX(1);
}

.impression__single:hover .impression__logo {
    height: 70px;
    left: 44%;
    top: 44%;
}