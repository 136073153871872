.svg__animation {
    transition: all .3s ease-in-out;
}

.scroll-animator {
    animation: move-svg 5s infinite;
}

@keyframes move-svg {
    0%    {
        opacity: 0;
        transform: translateY(-50px);
    }
    50%   {
        opacity: 1;
        transform: translateY(100px);
    }
    100%  {
        transform: translateY(-50px);
        opacity: 0;
    }
}


