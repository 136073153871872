/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    :root {
        font-size: 13px;
        line-height: 1.4;
        --size-heading: 2.3rem;
        --size-accent: 1.4rem;
        --size-small-heading: 1.7rem;
        --size-small-subheading: 1.3rem;
        --size-text: 1rem;
        --size-small: 1rem;
        --size-extra-small: .9rem;
        --size-small--accent: 1.2rem;
        --swiper-navigation-size: 35px;
    }

    .button {
        height: 45px;
        width: 190px;
        margin: 15px 0px;
    }

    .header {
        display: none;
        visibility: hidden;
    }

    .header-mobile {
        visibility: visible;
        display: flex;
    }

    .hero__text-wrapper--center {
        width: 100%;
    }

    .hero__heading--center {
        width: 100%;
    }

    .hero-left__heading {
        width: 65%;
    }

    .hero-left__text {
        width: 65%;
    }

    .hero-left__text-wrapper {
        width: 100%;
        padding: 100px 0px 120px 20px;
    }

    .hero-left__content {
        background: linear-gradient(to left, transparent,transparent 0%, white 75%);
    }

    .hero__content--center {
        background: linear-gradient(to Bottom, transparent,transparent 20%, white 85%);
    }

    .hero__svg {
        height: 250px;
        bottom: 12%;
        right: 5%;
    }

    .companies {
        width: 57%;
    }

    .companies__single {
        width: 48%;
    }

    .scroll-animator {
        right: 5%;
    }

    .input__label-file {
        padding: 11px 60px 11px 56px;
    }

    .cards__wrapper {
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        flex-wrap: wrap;
    }

    .card {
        width: 100%;
        margin: 25px 10px ;
    }

    .hero-content__wrapper {
        width: 100%;
    }

    .hero-content {
        height: 800px;
    }

    .cta__content-all {
        width: 100%;
    }

    .cta__content {
        width: 100%;
    }

    .cta__svg {
        width: 220px;
    }

    .cta__wrapper {
        flex-wrap: wrap;
    }

    .cta__input {
        width: 100%;
    }

    .cta__submit {
        width: 100% !important;
        margin-top: 20px !important;
    }

    .scroll-animator {
        display: none;
        visibility: hidden;
    }

    .teaser__wrapper {
        width: 100%;
        flex-wrap: wrap;
    }

    .headline {
        width: 100%;
    }

    .rating__single {
        width: 100%;
    }

    .contact__wrapper {
        width: 100%;
        flex-wrap: wrap;
        height: auto;
    }

    .contact__map-wrapper {
        max-height: 200px;
    }

    .contact__media {
        width: 100%;
    }

    .companies {
        width: 100%;
        margin-top: 100px;
        flex-wrap: wrap;
    }

    .slider__item {
        width: 32%;
    }

    .team__wrapper {
        width: 100%;
    }

    .team__single {
        width: 47%;
    }

    .application__wrapper {
        width: 100%;
    }

    .content__text-media {
        width: 100%;
    }

    .application__media {
        display: none;
        visibility: hidden;
    }

    .form__wrapper {
        width: 100%;
    }

    .footer__wrapper {
        width: 100%;
        padding-top: 0;
        padding-bottom: 20px;
        position: relative;
    }

    .footer__content {
        flex-wrap: wrap;
    }

    .footer__links--full-width {
        width: 100%;
    }

    .footer__links--full-width .footer__container {
        width: 100%;
    }

    .footer__links--full-width .footer__container .footer__link {
        text-align: center;
        font-weight: lighter;
        margin-top: 20px;
    }

    .footer__logo {
        margin-left: 20px;
    }

    .swiper-button-prev {
        left: 3px !important;
    }

    .swiper-button-next {
        right: 3px !important;
    }

    .flower-background, .flower-background--center, .flower-background--top, .flower-background--bottom, .flower-background--top-right {
        height: 200px;
    }

    .hero-content__button {
        width: 250px;
        height: 250px;
    }

    .hero-content__content {
        width: 100%;
        justify-content: center;
        background: linear-gradient(to Bottom, transparent,transparent 11%, white 45%)
    }

    .hero-content__content > * {
        padding: 0px 20px;
    }

    .hero-content__wrapper {
        position: relative;
        padding: 0;
    }

    .hero-content__slider {
        width: 100%;
        position: absolute;
        z-index: -1;
    }

    .hero-content__slider .swiper-pagination-all {
        display: none;
        visibility: hidden;
    }

    .impression > .swiper-button-next {
        display: none;
        visibility: hidden;
    }

    .impression > .swiper-button-prev {
        display: none;
        visibility: hidden;
    }

    .impression__wrapper {
        width: 100% !important;
        padding: 20px 30px;
    }

    .shop__wrapper > .swiper-button-next {
        display: none;
        visibility: hidden;
    }

    .shop__wrapper > .swiper-button-prev {
        display: none;
        visibility: hidden;
    }

    .shop__content {
        width: 100% !important;
        padding: 20px 30px;
    }

    .slider__wrapper > .swiper-button-next {
        display: none;
        visibility: hidden;
    }

    .slider__wrapper > .swiper-button-prev {
        display: none;
        visibility: hidden;
    }

    .slider__content {
        width: 100% !important;
        padding: 20px 30px;
    }

    .news__wrapper {
        flex-wrap: wrap;
        width: 100%;
    }

    .news__content {
        width: 100%;
    }

    .news__media {
        min-width: 100%;
        max-width: 100%;
        height: 250px;
    }

    .news > .swiper-button-next {
        display: none;
        visibility: hidden;
    }

    .news > .swiper-button-prev {
        display: none;
        visibility: hidden;
    }

    .news {
        flex-direction: column;
    }

    .news > .swiper-pagination-all {
        display: flex;
        visibility: visible;
    }

    .hero-left__content {
        height: 600px;
    }

    .hero-left {
        height: 600px;
    }

    .hero-left__text-wrapper {
        padding: 100px 20px 86px 20px;
    }

    .hero-left__background--video {
        height: 600px;
        max-height: 600px;
    }

    .hero-left__content {
        height: 600px;
    }

    .hero__content--center {
        height: 600px;
    }

    .hero__background--center {
        height: 600px;
    }

    .hero__text-wrapper--center {
        padding-bottom: 50px;
    }

    .hero {
        height: 600px;
    }

    .button {
        height: 40px;
        width: 180px;
        margin: 10px 0px 20px 0px;
    }

    .hero__svg {
        height: 120px;
        right: 7%;
        bottom: 25%;
    }

    .news {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .news__media {
        height: 230px;
    }

    .news__text {
        padding: 15px 0px;
    }

    .swiper-button-next, .swiper-button-prev {
        padding: 27px;
    }

    .header-mobile {
        height: 55px;
    }

    .header__link {
        font-size: 1.1rem;
    }

    .logo {
        min-width: 200px;
        height: 100px;
    }

    .logo__svg {
        width: 120px;
    }

    .flower-background {
        height: 200px;
    }

    .flower-background--top {
        height: 200px;
    }

    .flower-background--bottom, .flower-background--top-right {
        height: 200px;
    }

    .svg-separator {
        height: 60px;
    }

    .svg-separator--scale {
        transform: scale(.6);
    }

    .svg-logo__small {
        height: 50px;
    }

    .teaser {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .teaser__media {
        height: 230px;
        max-height: 230px;
        min-width: 100%;
        object-fit: cover;
        padding-top: 10px;
    }

    .teaser__svg--scale {
        height: 80px;
    }

    .teaser__content {
        gap: 0px;
        width: 100%;
    }

    .impression__heading {
        padding: 15px 0px 0px 0px;
    }

    .impression__media {
        min-width: 100%;
        max-width: 100%;
        min-height: 200px;
        max-height: 200px;
        object-fit: cover;
    }

    .impression > .swiper-pagination-all {
        padding: 0;
    }

    .companies__contacts {
        padding: 10px 20px;
    }

    .contact__wrapper {
        height: auto;
        width: 100%;
    }

    .contact__media {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .address {
        width: 100%;
        margin-bottom: 10px;
    }

    .companies {
        flex-wrap: wrap;
    }

    .companies__single {
        width: 100%;
        margin-bottom: 40px;
        height: auto;
        border: 3px solid var(--green-light-dark);
    }

    .contact {
        padding-bottom: 0;
    }

    .address__wrapper {
        padding: 0;
        width: 100%;
    }

    .companies__heading {
        padding: 40px 10px 10px 10px;
        height: 80px;
    }

    .address__needle {
        height: 50px;
        margin-right: 15px;
    }

    .contact__map {
        height: 200px;
        min-width: 55%;
        max-width: 55%;
    }

    .companies {
        width: 100%;
        margin-top: 80px;
    }

    .companies__opening-hours {
        padding: 0px 10px;
    }

    .companies__contact-icon {
        margin-right: 10px;
        width: 20px;
        height: 20px;
    }

    .companies__contacts {
        padding: 10px 10px;
    }

    .companies__icon {
        min-width: 50px;
        max-width: 50px;
        height: 50px;
    }

    .companies__icon-wrapper {
        width: 75px;
        height: 75px;
        left: calc(50% - 37px);
        top: -40px;
    }

    .contact__heading {
        padding: 15px 0px 15px 0px;
    }

    .content__headline {
        padding-bottom: 10px;
    }

    .hero-content {
        height: 650px;
    }

    .hero-content__wrapper {
        padding-top: 20px;
        padding-bottom: 20px;
        align-items: flex-end;
    }

    .hero-content__button {
        width: 200px;
        height: 200px;
        bottom: 0px;
        left: 20px;
    }

    .hero-content__link-text {
        letter-spacing: 5px;
    }

    .hero-content__small-heading {
        margin-top: 140px;
    }

    .hero-content__content--end {
        justify-content: flex-end;
    }

    .hero-content__slider-wrapper {
        height: 80% !important;
    }

    .single-headline__heading {
        padding: 10px 0px 0px 0px;
    }

    .card {
        height: 210px;
    }

    .card__text {
        padding: 10px 20px;
    }

    .cards__wrapper {
        padding: 0;
    }

    .card__media {
        height: 75px;
        width: 75px;
        top: -40px;
        left: 41%;
    }

    .card__heading {
        padding: 35px 10px 0px 10px;
    }

    .card__img {
        min-width: 60px;
        max-width: 60px;
        height: 60px;
    }

    .rating {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .rating__single {
        padding-top: 0;
    }

    .shop__heading {
        padding: 5px 0px 5px 0px;
    }

    .shop__img {
        height: 120px;
    }

    .shop__text {
        height: 120px;
        padding: 10px 15px;
    }

    .shop__linkarea {
        height: 45px;
    }

    .shop__icon {
        width: 55px;
    }

    .shop__content {
        padding-bottom: 20px;
    }

    .content {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .content__text-media {
        padding-top: 0px;
        padding-bottom: 0px;
        flex-wrap: wrap;
    }

    .content__text-media--reverse {
        flex-wrap: wrap-reverse;
    }

    .content__fullwidth {
        max-height: 370px;
    }

    .content__media {
        height: 250px;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .content__content {
        width: 100%;
    }

    .cta__input, .cta__submit {
        height: 35px !important;
        font-size: 1.1rem !important;
    }

    .cta__wrapper {
        padding: 10px 0px;
    }

    .cta__content {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .cta__svg {
        width: 100px;
    }

    .cta__submit {
        width: 100% !important;
    }

    .cta__input {
        width: 100% !important;
    }

    .swiper-pagination-bullet-active {
        border: 10px solid var(--green-light-dark);
    }

    .swiper-pagination-bullet {
        width: 30px !important;
        height: 30px !important;
    }

    .swiper-pagination-all {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .slider__heading {
        padding: 5px 0px;
    }

    .slider__linkarea {
        height: 40px;
    }

    .slider__img {
        height: 120px;
    }

    .slider__name {
        font-size: 1.2rem;
    }

    .slider__link {
        letter-spacing: 5px;
    }

    .slider__text {
        height: 180px;
        padding: 10px 15px;
    }

    .slider__text-small {
        font-size: .9rem;
    }

    .slider__wrapper > .swiper-pagination-all {
        padding: 0;
    }

    .team {
        padding: 25px 0px;
    }

    .team__heading {
        padding: 5px 0px;
    }

    .team__single {
        height: 170px;
        width: 33%;
    }

    .team__content {
        flex-wrap: wrap;
        padding: 10px 5px;
    }

    .team__position {
        display: none;
        visibility: hidden;
    }

    .team__person {
        width: 100%;
    }

    .team__single--active {
        width: 49%;
    }

    .team__svg {
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }

    .cta__content-all {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .job {
        padding: 25px 30px;
        flex-wrap: wrap;
    }

    .job__action--unexpanded {
        padding-top: 15px;
    }

    .jobs__heading {
        padding: 15px 0px;
    }

    .jobs {
        padding: 50px 0px;
    }

    .job__link {
        width: 30%;
    }

    .job__arrow--right, .job__arrow--top {
        height: 35px;
    }

    .jobs__wrapper {
        width: 100%;
    }

    .job__content {
        width: 100%;
    }

    .job__link {
        width: 100%;
        padding-top: 40px;
    }

    .job__link-text {
        letter-spacing: 4px;
        text-align: center;
        width: 100%;
        height: 100%;
    }

    .application {
        padding: 50px 0px;
    }

    .application__headline {
        padding: 5px 0px;
    }

    .custom-file-upload {
        margin-bottom: 0;
    }

    .input__field {
        height: 35px;
    }

    .form input {
        margin-bottom: 15px;
    }

    .footer__svg {
        width: 150px;
    }

    .footer__logo {
        width: 35px;
        height: 35px;
        margin-left: 10px;
    }

    .hotspot-header {
        width: 100%;
        right: 0;
        top: -80px;
        border-radius: 0;
    }

    .hotspot {
        height: 400px;
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .hotspot-header {
        padding: 10px 25px;
    }

    .hotspot__content {
        padding: 10px;
        width: 100%;
        margin-top: 10px;
        display: none;
        visibility: hidden;
    }

    .hotspot__content--active {
        opacity: 0;
    }

    .hotspot__single {
        width: 250px;
        flex-direction: column;
    }

    .hotspot__svg {
        width: 50px;
        height: 50px;
    }

    .hotspot__content--mobile {
        bottom: -120px;
        position: absolute;
        width: 100%;
        border-radius: 0;
        padding: 0;
        transition: all .7s ease-in-out;
    }

    .hotspot__text--mobile {
        height: 0;
        opacity: 0;
        transition: all .7s ease-in-out;
        display: flex;
        visibility: visible;
    }

    .hotspot__content--mobile--active {
        display: flex;
        visibility: visible;
        padding: 10px 20px;
        transition: all .7s ease-in-out;
    }

    .hotspot__content--mobile--active > .hotspot__text--mobile {
        height: auto;
        opacity: 1;
        transition: all .7s ease-in-out;
    }

    .hero-content__content--padding {
        padding-bottom: 150px;
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
    :root {
        font-size: 13px;
        line-height: 1.4;
        --size-heading: 2.3rem;
        --size-accent: 1.4rem;
        --size-small-heading: 1.7rem;
        --size-small-subheading: 1.3rem;
        --size-text: 1rem;
        --size-small: 1rem;
        --size-extra-small: .9rem;
        --size-small--accent: 1.2rem;
        --swiper-navigation-size: 35px;
    }

    .header {
        display: none;
        visibility: hidden;
    }

    .header-mobile {
        visibility: visible;
        display: flex;
    }

    .hero__text-wrapper--center {
        width: 100%;
    }

    .hero__heading--center {
        width: 100%;
    }

    .hero-left__heading {
        width: 65%;
    }

    .hero-left__text-wrapper {
        width: 100%;
        padding: 100px 0px 120px 20px;
    }

    .hero-left__content {
        background: linear-gradient(to left, transparent,transparent 0%, white 75%);
    }

    .hero__content--center {
        background: linear-gradient(to Bottom, transparent,transparent 20%, white 85%);
    }

    .hero__svg {
        height: 250px;
        bottom: 12%;
        right: 5%;
    }

    .companies {
        width: 57%;
    }

    .companies__single {
        width: 48%;
    }

    .scroll-animator {
        right: 5%;
    }

    .input__label-file {
        padding: 7px 55px 11px 56px;
    }

    .cards__wrapper {
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        flex-wrap: wrap;
    }

    .card__media {
        left: 37%;
    }

    .card {
        width: 44%;
        margin: 40px 10px ;
    }

    .hero-content__wrapper {
        width: 100%;
    }

    .cta__content-all {
        width: 100%;
    }

    .cta__content {
        width: 100%;
    }

    .cta__svg {
        width: 220px;
    }

    .scroll-animator {
        display: none;
        visibility: hidden;
    }

    .teaser__wrapper {
        width: 100%;
    }

    .headline {
        width: 100%;
    }

    .rating__single {
        width: 100%;
    }

    .contact__wrapper {
        width: 100%;
        flex-wrap: wrap;
        height: auto;
    }

    .contact__media {
        width: 100%;
    }

    .companies {
        width: 100%;
        margin-top: 100px;
    }

    .slider__item {
        width: 32%;
    }

    .team__wrapper {
        width: 100%;
    }

    .team__single {
        width: 47%;
    }

    .job {
        padding: 55px 30px;
    }

    .jobs__wrapper {
        width: 100%;
    }

    .job__content {
        width: 70%;
    }

    .job__link {
        width: 25%;
    }

    .job__link-text {
        letter-spacing: 4px;
        text-align: center;
    }

    .application__wrapper {
        width: 100%;
    }

    .content__text-media {
        width: 100%;
    }

    .application__media {
        display: none;
        visibility: hidden;
    }

    .form__wrapper {
        width: 100%;
    }

    .footer__wrapper {
        width: 100%;
        padding-top: 0;
        padding-bottom: 20px;
        position: relative;
    }

    .footer__links--full-width {
        width: 100%;
    }

    .footer__links--full-width .footer__container {
        width: 100%;
    }

    .footer__links--full-width .footer__container .footer__link {
        text-align: center;
        font-weight: lighter;
        margin-top: 20px;
    }

    .footer__content {
        flex-wrap: wrap;
    }

    .footer__logo {
        margin-left: 20px;
    }

    .swiper-button-prev {
        left: 3px !important;
    }

    .swiper-button-next {
        right: 3px !important;
    }

    .flower-background, .flower-background--center, .flower-background--top, .flower-background--bottom, .flower-background--top-right {
        height: 250px;
    }

    .hero-content__content {
        width: 55%;
    }

    .hero-content__slider {
        width: 40%;
        height: auto;
        max-height: 350px;
    }

    .news__wrapper {
        width: 100%;
    }

    .news > .swiper-button-next {
        display: none;
        visibility: hidden;
    }

    .news > .swiper-button-prev {
        display: none;
        visibility: hidden;
    }

    .news {
        flex-direction: column;
    }

    .news > .swiper-pagination-all {
        display: flex;
        visibility: visible;
    }


    .hero-left__content {
        height: 450px;
    }

    .hero-left {
        height: 450px;
    }

    .hero-left__text-wrapper {
        padding: 100px 0px 86px 40px;
    }

    .hero-left__background--video {
        height: 450px;
        max-height: 450px;
    }

    .hero-left__content {
        height: 450px;
    }

    .hero__content--center {
        height: 450px;
    }

    .hero__background--center {
        height: 450px;
    }

    .hero__text-wrapper--center {
        padding-bottom: 20px;
    }

    .hero {
        height: 450px;
    }

    .button {
        height: 40px;
        width: 180px;
        margin: 10px 0px 20px 0px;
    }

    .hero__svg {
        height: 120px;
        right: 25%;
        bottom: 10%;
    }

    .news {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .news__media {
        height: 230px;
    }

    .news__text {
        padding: 15px 0px;
    }

    .swiper-button-next, .swiper-button-prev {
        padding: 27px;
    }

    .header-mobile {
        height: 55px;
    }

    .header__link {
        font-size: 1.1rem;
    }

    .logo {
        min-width: 200px;
        height: 100px;
    }

    .logo__svg {
        width: 120px;
    }

    .flower-background {
        height: 250px;
    }

    .flower-background--top {
        height: 250px;
    }

    .flower-background--bottom, .flower-background--top-right {
        height: 250px;
    }

    .svg-separator {
        height: 60px;
    }

    .svg-separator--scale {
        transform: scale(.6);
    }

    .svg-logo__small {
        height: 50px;
    }

    .teaser {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .teaser__media {
        height: 230px;
        max-height: 230px;
    }

    .teaser__svg--scale {
        height: 80px;
    }

    .impression__heading {
        padding: 15px 0px 0px 0px;
    }

    .impression__media {
        min-width: 100%;
        max-width: 100%;
        min-height: 200px;
        max-height: 200px;
        object-fit: cover;
    }

    .impression > .swiper-pagination-all {
        padding: 0;
    }

    .companies__contacts {
        padding: 10px 20px;
    }

    .contact__wrapper {
        height: auto;
        width: 100%;
    }

    .contact__media {
        flex-direction: row;
    }

    .address {
        width: 40%;
    }

    .address__wrapper {
        padding: 0px 0px 0px 20px;
    }

    .companies__heading {
        padding: 40px 20px 10px 20px;
    }

    .address__needle {
        height: 65px;
    }

    .contact__map {
        height: 200px;
        width: 100%;
    }

    .contact__map-wrapper {
        max-height: 200px;
        width: 55%;
    }

    .companies {
        width: 100%;
        margin-top: 80px;
    }

    .companies__single {
        width: 45%;
    }

    .companies__icon {
        min-width: 70px;
        max-width: 70px;
        height: 70px;
    }

    .companies__icon-wrapper {
        width: 100px;
        height: 100px;
        left: calc(50% - 50px);
    }

    .contact__heading {
        padding: 15px 0px 15px 0px;
    }

    .hero-content {
        height: auto;
        margin-top: 50px;
    }

    .hero-content__wrapper {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    .hero-content__button {
        width: 120px;
        height: 120px;
        bottom: 0px;
    }

    .hero-content__flower {
        width: 90px;
    }

    .hero-content__link-text {
        letter-spacing: 5px;
    }

    .hero-content__small-heading {
        margin-top: 15px;
    }

    .hero-content__slider-wrapper {
        height: 75% !important;
    }

    .single-headline__heading {
        padding: 10px 0px 0px 0px;
    }

    .card {
        height: 260px;
    }

    .card__text {
        padding: 10px 20px;
    }

    .cards__wrapper {
        padding: 0;
    }

    .card__media {
        height: 75px;
        width: 75px;
        top: -40px;
        left: 37%;
    }

    .card__heading {
        padding: 35px 10px 0px 10px;
    }

    .card__img {
        min-width: 60px;
        max-width: 60px;
        height: 60px;
    }

    .shop__heading {
        padding: 5px 0px 5px 0px;
    }

    .shop__img {
        height: 120px;
    }

    .shop__text {
        height: 120px;
        padding: 10px 15px;
    }

    .shop__linkarea {
        height: 45px;
    }

    .shop__icon {
        width: 55px;
    }

    .shop__content {
        padding-bottom: 20px;
    }

    .content__text-media {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .content__fullwidth {
        max-height: 370px;
    }

    .content__media {
        height: 250px;
    }

    .cta__input, .cta__submit {
        height: 35px !important;
        font-size: 1.1rem !important;
    }

    .cta__wrapper {
        padding: 10px 0px;
    }

    .cta__content {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .cta__svg {
        width: 100px;
    }

    .cta__submit {
        width: 100% !important;
    }

    .cta__input {
        width: 100% !important;
    }

    .swiper-pagination-bullet-active {
        border: 10px solid var(--green-light-dark);
    }

    .swiper-pagination-bullet {
        width: 30px !important;
        height: 30px !important;
    }

    .swiper-pagination-all {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .slider__heading {
        padding: 5px 0px;
    }

    .slider__linkarea {
        height: 40px;
    }

    .slider__img {
        height: 120px;
    }

    .slider__name {
        font-size: 1.2rem;
    }

    .slider__link {
        letter-spacing: 5px;
    }

    .slider__text {
        height: 180px;
        padding: 10px 15px;
    }

    .slider__text-small {
        font-size: .9rem;
    }

    .slider__wrapper > .swiper-pagination-all {
        padding: 0;
    }

    .team {
        padding: 25px 0px;
    }

    .team__heading {
        padding: 5px 0px;
    }

    .team__single {
        height: 170px;
        width: 33%;
    }

    .team__content {
        padding: 10px 10px;
    }

    .team__svg {
        width: 25px;
    }

    .cta__content-all {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .job {
        padding: 25px 30px;
    }

    .job__action--unexpanded {
        padding-top: 15px;
    }

    .jobs__heading {
        padding: 15px 0px;
    }

    .jobs {
        padding: 50px 0px;
    }

    .job__link {
        width: 30%;
    }

    .job__content {
        width: 67%;
    }

    .job__arrow--right, .job__arrow--top {
        height: 35px;
    }

    .application {
        padding: 50px 0px;
    }

    .application__headline {
        padding: 5px 0px;
    }

    .custom-file-upload {
        margin-bottom: 0;
    }

    .input__field {
        height: 35px;
    }

    .form input {
        margin-bottom: 15px;
    }

    .footer__svg {
        width: 200px;
    }

    .footer__logo {
        width: 50px;
        height: 50px;
    }

    .hotspot-header {
        width: 80%;
        right: 2%;
        top: -60px;
    }

    .hotspot {
        height: 400px;
        margin-top: 75px;
    }

    .hotspot-header {
        padding: 10px 25px;
    }

    .hotspot__content {
        padding: 10px;
        width: 100%;
        margin-top: 10px;
    }

    .hotspot__content--active {
        opacity: 0;
    }

    .hotspot__single {
        width: 250px;
        flex-direction: column;
    }

    .hotspot__svg {
        width: 50px;
        height: 50px;
    }

    .content__media {
        height: auto;
        max-height: 250px;
    }

    .team__single--active {
        width: 49%;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
    :root {
        font-size: 13px;
        line-height: 1.4;
        --size-heading: 2.3rem;
        --size-accent: 1.4rem;
        --size-small-heading: 1.9rem;
        --size-small-subheading: 1.3rem;
        --size-text: 1rem;
        --size-small: 1rem;
        --size-extra-small: .9rem;
        --size-small--accent: 1.2rem;
        --swiper-navigation-size: 35px;
    }

    .header {
        display: none;
        visibility: hidden;
    }

    .header-mobile {
        visibility: visible;
        display: flex;
    }
    .logo {
        min-width: 240px;
        height: 120px;
    }

    .hero__text-wrapper--center {
        width: 100%;
    }

    .hero__heading--center {
        width: 100%;
    }

    .hero-left__heading {
        width: 60%;
    }

    .hero-left__content {
        background: linear-gradient(to left, transparent,transparent 0%, white 75%);
    }

    .hero-left__text-wrapper {
        width: 100%;
        padding: 100px 0px 120px 20px;
    }

    .hero__content--center {
        background: linear-gradient(to Bottom, transparent,transparent 20%, white 85%);
    }

    .hero__svg {
        height: 300px;
    }

    .scroll-animator {
        right: 5%;
    }

    .input__label-file {
        padding: 7px 54px 11px 56px;
    }

    .cards__wrapper {
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        flex-wrap: wrap;
    }

    .card__media {
        left: 37%;
    }

    .card {
        width: 44%;
        margin: 30px 20px ;
    }

    .hero-content__wrapper {
        width: 100%;
    }

    .cta__content-all {
        width: 80%;
    }

    .scroll-animator {
        display: none;
        visibility: hidden;
    }

    .teaser__wrapper {
        width: 100%;
    }

    .headline {
        width: 100%;
    }

    .rating__single {
        width: 100%;
    }

    .contact__wrapper {
        width: 100%;
        flex-wrap: wrap;
        height: auto;
    }

    .contact__media {
        width: 100%;
    }

    .contact__map-wrapper {
        width: 55%;
        max-height: 200px;
    }

    .slider__item {
        width: 32%;
    }

    .team__wrapper {
        width: 100%;
    }

    .jobs__wrapper {
        width: 100%;
    }

    .application__wrapper {
        width: 100%;
    }

    .content__text-media {
        width: 100%;
    }

    .footer__wrapper {
        width: 100%;
        padding-top: 0;
        padding-bottom: 20px;
        position: relative;
    }


    .footer__links--full-width {
        width: 100%;
    }

    .footer__links--full-width .footer__container {
        width: 100%;
    }

    .footer__links--full-width .footer__container .footer__link {
        text-align: center;
        font-weight: lighter;
        margin-top: 20px;
    }

    .footer__content {
        flex-wrap: wrap;
    }

    .swiper-button-prev {
        left: 10px !important;
    }

    .swiper-button-next {
        right: 10px !important;
    }

    .hero-left__content {
        height: 450px;
    }

    .hero-left {
        height: 450px;
    }

    .hero-left__text-wrapper {
        padding: 100px 0px 86px 40px;
    }

    .hero-left__background--video {
        height: 450px;
        max-height: 450px;
    }

    .hero-left__content {
        height: 450px;
    }

    .hero__content--center {
        height: 450px;
    }

    .hero__background--center {
        height: 450px;
    }

    .hero__text-wrapper--center {
        padding-bottom: 20px;
    }

    .hero {
        height: 450px;
    }

    .button {
        height: 40px;
        width: 180px;
        margin: 10px 0px 20px 0px;
    }

    .hero__svg {
        height: 150px;
        right: 25%;
        bottom: 10%;
    }

    .news {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .news__media {
        height: 270px;
    }

    .news__text {
        padding: 15px 0px;
    }

    .swiper-button-next, .swiper-button-prev {
        padding: 27px;
    }

    .header-mobile {
        height: 55px;
    }

    .header__link {
        font-size: 1.1rem;
    }

    .logo {
        min-width: 200px;
        height: 100px;
    }

    .logo__svg {
        width: 120px;
    }

    .flower-background {
        height: 250px;
    }

    .flower-background--top {
        height: 250px;
    }

    .flower-background--bottom, .flower-background--center, .flower-background--top-right {
        height: 250px;
    }

    .svg-separator {
        height: 60px;
    }

    .svg-separator--scale {
        transform: scale(.6);
    }

    .svg-logo__small {
        height: 50px;
    }

    .teaser {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .teaser__media {
        height: 230px;
        max-height: 230px;
    }

    .teaser__svg--scale {
        height: 80px;
    }

    .impression__heading {
        padding: 15px 0px 0px 0px;
    }

    .impression__media {
        min-width: 100%;
        max-width: 100%;
        min-height: 200px;
        max-height: 200px;
        object-fit: cover;
    }

    .impression > .swiper-pagination-all {
        padding: 0;
    }

    .companies__contacts {
        padding: 10px 20px;
    }

    .contact__wrapper {
        height: auto;
        width: 80%;
    }

    .contact__media {
        flex-direction: row;
    }

    .address {
        width: 43%;
    }

    .address__wrapper {
        padding: 0px 0px 0px 20px;
    }

    .companies__heading {
        padding: 40px 20px 10px 20px;
    }

    .address__needle {
        height: 65px;
    }

    .contact__map {
        height: 200px;
        width: 100%;
    }

    .companies {
        width: 100%;
        margin-top: 80px;
    }

    .companies__single {
        width: 45%;
    }

    .companies__icon {
        min-width: 70px;
        max-width: 70px;
        height: 70px;
    }

    .companies__icon-wrapper {
        width: 100px;
        height: 100px;
        left: calc(50% - 50px);
    }

    .contact__heading {
        padding: 15px 0px 15px 0px;
    }

    .hero-content {
        height: 400px;
        margin-top: 50px;
    }

    .hero-content__wrapper {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    .hero-content__small-heading {
        margin-top: 30px;
    }

    .hero-content__button {
        width: 200px;
        height: 200px;
        bottom: 30px;
    }

    .hero-content__link-text {
        letter-spacing: 5px;
    }

    .hero-content__slider-wrapper {
        height: 75% !important;
    }

    .single-headline__heading {
        padding: 10px 0px 0px 0px;
    }

    .card {
        height: 220px;
        margin: 20px 20px;
    }

    .card__text {
        padding: 10px 20px;
    }

    .cards__wrapper {
        padding: 0;
    }

    .card__media {
        height: 75px;
        width: 75px;
        top: -40px;
        left: 40%;
    }

    .card__heading {
        padding: 35px 10px 0px 10px;
    }

    .card__img {
        min-width: 60px;
        max-width: 60px;
        height: 60px;
    }

    .shop__heading {
        padding: 5px 0px 5px 0px;
    }

    .shop__img {
        height: 120px;
    }

    .shop__text {
        height: 120px;
        padding: 10px 15px;
    }

    .shop__linkarea {
        height: 45px;
    }

    .shop__icon {
        width: 55px;
    }

    .shop__content {
        padding-bottom: 20px;
    }

    .content__text-media {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .content__fullwidth {
        max-height: 370px;
    }

    .content__media {
        height: 250px;
    }

    .cta__input, .cta__submit {
        height: 35px !important;
        font-size: 1.1rem !important;
    }

    .cta__wrapper {
        padding: 10px 0px;
    }

    .cta__content {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .cta__svg {
        width: 120px;
    }

    .cta__submit {
        width: 100% !important;
    }

    .cta__input {
        width: 100% !important;
    }

    .swiper-pagination-bullet-active {
        border: 10px solid var(--green-light-dark);
    }

    .swiper-pagination-bullet {
        width: 30px !important;
        height: 30px !important;
    }

    .swiper-pagination-all {
        padding-top: 15px;
    }

    .slider__heading {
        padding: 5px 0px;
    }

    .slider__linkarea {
        height: 55px;
    }

    .slider__img {
        height: 140px;
    }

    .slider__text {
        height: 180px;
        padding: 10px 15px;
    }

    .slider__text-small {
        font-size: .9rem;
    }

    .slider__wrapper > .swiper-pagination-all {
        padding: 0;
    }

    .team {
        padding: 25px 0px;
    }

    .team__heading {
        padding: 5px 0px;
    }

    .team__single {
        height: 200px;
    }

    .team__content {
        padding: 10px 10px;
    }

    .team__svg {
        width: 25px;
    }

    .cta__content-all {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .job {
        padding: 25px 50px;
    }

    .job__action--unexpanded {
        padding-top: 15px;
    }

    .jobs__heading {
        padding: 15px 0px;
    }

    .jobs {
        padding: 50px 0px;
    }

    .job__arrow--right, .job__arrow--top {
        height: 40px;
    }

    .application {
        padding: 50px 0px;
    }

    .application__headline {
        padding: 5px 0px;
    }

    .custom-file-upload {
        margin-bottom: 0;
    }

    .input__field {
        height: 35px;
    }

    .form input {
        margin-bottom: 15px;
    }

    .footer__svg {
        width: 230px;
    }

    .footer__logo {
        width: 60px;
        height: 60px;
    }

    .hotspot {
        height: 550px;
    }

    .hotspot-header {
        padding: 10px 25px;
    }

    .hotspot__content {
        padding: 15px;
        width: 100%;
        margin-top: 10px;
    }

    .hotspot__single {
        width: 300px;
        flex-direction: column;
    }

    .hotspot__svg {
        width: 50px;
        height: 50px;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    :root {
        font-size: 14px;
        line-height: 1.4;
        --size-heading: 2.7rem;
        --size-accent: 1.5rem;
        --size-small-heading: 2.3rem;
        --size-small-subheading: 1.3rem;
        --size-text: 1rem;
        --size-small: 1rem;
    }

    .companies {
        width: 57%;
    }

    .companies__single {
        width: 48%;
    }

    .scroll-animator {
        right: 5%;
    }

    .input__label-file {
        padding: 7px 52px 11px 56px;
    }

    .cards__wrapper {
        justify-content: space-between;
        padding-left: 10px;
        padding-right: 10px;
    }

    .card__media {
        left: 30%;
    }

    .card {
        margin: 0;
    }

    .teaser__wrapper {
        width: 100%;
    }

    .headline {
        width: 100%;
    }

    .rating__single {
        width: 100%;
    }

    .contact__wrapper {
        width: 100%;
    }

    .slider__item {
        width: 32%;
    }

    .team__wrapper {
        width: 100%;
    }

    .jobs__wrapper {
        width: 100%;
    }

    .application__wrapper {
        width: 100%;
    }

    .content__text-media {
        width: 100%;
    }

    .swiper-button-prev {
        left: 10px !important;
    }

    .swiper-button-next {
        right: 10px !important;
    }

    .hero-left__content {
        height: 550px;
    }

    .hero-left {
        height: 550px;
    }

    .hero-left__background--video {
        height: 550px;
        max-height: 550px;
    }

    .hero-left__content {
        height: 550px;
    }

    .hero__content--center {
        height: 500px;
    }

    .hero__background--center {
        height: 500px;
    }

    .hero {
        height: 550px;
    }

    .button {
        height: 45px;
        width: 200px;
        margin: 10px 0px 20px 0px;
    }

    .hero__svg {
        height: 200px;
        right: 25%;
        bottom: 10%;
    }

    .news {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .news__media {
        height: 300px;
    }

    .header__link {
        font-size: 1.1rem;
    }

    .logo {
        padding: 0;
    }

    .logo__svg {
        width: 120px;
    }

    .hero-left__text-wrapper {
        padding: 100px 0px 86px 0px;
    }

    .flower-background {
        height: 300px;
    }

    .flower-background--top {
        height: 300px;
    }

    .flower-background--bottom, .flower-background--center, .flower-background--top-right {
        height: 300px;
    }

    .svg-separator {
        height: 70px;
    }

    .svg-separator--scale {
        transform: scale(.7);
    }

    .svg-logo__small {
        height: 60px;
    }

    .teaser {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .teaser__media {
        height: 270px;
        max-height: 270px;
    }

    .teaser__svg--scale {
        height: 100px;
    }

    .impression__heading {
        padding: 15px 0px 0px 0px;
    }

    .impression__media {
        min-width: 100%;
        max-width: 100%;
        min-height: 300px;
        max-height: 300px;
        object-fit: cover;
    }

    .companies__contacts {
        padding: 10px 20px;
    }

    .contact__wrapper {
        height: 380px;
    }

    .companies__heading {
        padding: 40px 20px 10px 20px;
    }

    .address__needle {
        height: 65px;
    }

    .contact__map {
        height: 250px;
    }

    .companies__icon {
        min-width: 70px;
        max-width: 70px;
        height: 70px;
    }

    .companies__icon-wrapper {
        width: 100px;
        height: 100px;
        left: calc(50% - 50px);
    }

    .contact__heading {
        padding: 25px 0px 35px 0px;
    }

    .contact__map-wrapper {
        max-height: 250px;
    }

    .hero-content {
        height: 500px;
    }

    .hero-content__wrapper {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    .hero-content__button {
        width: 275px;
        height: 275px;
        bottom: 40px;
    }

    .hero-content__small-heading {
        margin-top: 50px;
    }

    .single-headline__heading {
        padding: 10px 0px 0px 0px;
    }

    .cards__wrapper {
        padding: 50px 0px 25px 0px;
    }

    .card {
        height: 350px;
    }

    .card__text {
        padding: 10px 20px;
    }

    .shop__heading {
        padding: 15px 0px 15px 0px;
    }

    .shop__img {
        height: 170px;
    }

    .shop__text {
        height: 135px;
    }

    .shop__linkarea {
        height: 55px;
    }

    .shop__icon {
        width: 55px;
    }

    .shop__content {
        padding-bottom: 20px;
    }

    .content__text-media {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .content__fullwidth {
        max-height: 500px;
    }

    .content__media {
        height: 300px;
    }

    .cta__input, .cta__submit {
        height: 40px !important;
        font-size: 1.3rem !important;
    }

    .cta__content {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .cta__svg {
        width: 170px;
    }

    .cta__submit {
        width: 100% !important;
    }

    .cta__input {
        width: 100% !important;
    }

    .swiper-pagination-bullet-active {
        border: 10px solid var(--green-light-dark);
    }

    .swiper-pagination-bullet {
        width: 30px !important;
        height: 30px !important;
    }

    .swiper-pagination-all {
        padding-top: 15px;
    }

    .slider__heading {
        padding: 15px 0px;
    }

    .slider__linkarea {
        height: 55px;
    }

    .slider__img {
        height: 140px;
    }

    .slider__text {
        height: 180px;
        padding: 10px 15px;
    }

    .slider__text-small {
        font-size: .9rem;
    }

    .slider__wrapper > .swiper-pagination-all {
        padding: 0;
    }

    .team {
        padding: 25px 0px;
    }

    .team__heading {
        padding: 15px 0px;
    }

    .team__single {
        height: 250px;
    }

    .cta__content-all {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .job {
        padding: 30px 50px;
    }

    .jobs__heading {
        padding: 15px 0px;
    }

    .jobs {
        padding: 50px 0px;
    }

    .application {
        padding: 50px 0px;
    }

    .application__headline {
        padding: 15px 0px;
    }

    .custom-file-upload {
        margin-bottom: 0;
    }

    .input__field {
        height: 35px;
    }

    .form input {
        margin-bottom: 15px;
    }

    .hotspot {
        height: 550px;
    }

    .hotspot-header {
        padding: 10px 25px;
    }

    .hotspot__content {
        padding: 15px;
    }

    .hotspot__single {
        width: 400px;
    }

    .hotspot__svg {
        width: 50px;
        height: 50px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
    :root {
        font-size: 14px;
        line-height: 1.4;
        --size-heading: 3rem;
        --size-accent: 1.7rem;
        --size-small-heading: 2.5rem;
        --size-small-subheading: 1.5rem;
        --size-text: 1rem;
        --size-small: 1rem;
    }

    .companies {
        width: 57%;
    }

    .companies__single {
        width: 48%;
    }

    .scroll-animator {
        right: 5%;
    }

    .input__label-file {
        padding: 7px 50px 11px 56px;
    }

    .shop__link {
        font-size: 1.1rem;
    }

    .hero-left__content {
        height: 600px;
    }

    .hero-left {
        height: 600px;
    }

    .hero-left__background--video {
        height: 600px;
        max-height: 600px;
    }

    .hero-left__content {
        height: 600px;
    }

    .hero__content--center {
        height: 600px;
    }

    .hero__background--center {
        height: 600px;
    }

    .hero {
        height: 600px;
    }

    .button {
        height: 50px;
        width: 230px;
        margin: 20px 0px;
    }

    .hero__svg {
        height: 250px;
        right: 25%;
        bottom: 10%;
    }

    .news {
        margin-top: 75px;
        margin-bottom: 75px;
    }

    .news__media {
        height: 350px;
    }

    .header__link {
        font-size: 1.2rem;
    }

    .hero-left__text-wrapper {
        padding: 100px 0px 86px 0px;
    }

    .flower-background {
        height: 350px;
    }

    .flower-background--top {
        height: 350px;
    }

    .flower-background--bottom, .flower-background--center, .flower-background--top-right {
        height: 350px;
    }

    .svg-separator {
        height: 80px;
    }

    .svg-separator--scale {
        transform: scale(.8);
    }

    .svg-logo__small {
        height: 60px;
    }

    .teaser__media {
        height: 300px;
        max-height: 300px;
    }

    .teaser__svg--scale {
        height: 120px;
    }

    .impression__heading {
        padding: 15px 0px 0px 0px;
    }

    .impression__media {
        min-width: 100%;
        max-width: 100%;
        min-height: 350px;
        max-height: 350px;
        object-fit: cover;
    }

    .contact__wrapper {
        height: 440px;
    }

    .hero-content {
        height: 650px;
    }

    .hero-content__button {
        width: 300px;
        height: 300px;
        bottom: 40px;
    }

    .single-headline__heading {
        padding: 10px 0px 0px 0px;
    }

    .cards__wrapper {
        padding: 50px 0px 25px 0px;
    }

    .card {
        height: 350px;
    }

    .shop__heading {
        padding: 15px 0px 15px 0px;
    }

    .shop__img {
        height: 200px;
    }

    .shop__content {
        padding-bottom: 20px;
    }

    .cta__input, .cta__submit {
        height: 50px !important;
    }

    .swiper-pagination-bullet-active {
        border: 12px solid var(--green-light-dark);
    }

    .swiper-pagination-bullet {
        width: 35px !important;
        height: 35px !important;
    }

    .swiper-pagination-all {
        padding-top: 15px;
    }

    .slider__heading {
        padding: 15px 0px;
    }

    .slider__linkarea {
        height: 60px;
    }

    .slider__img {
        height: 160px;
    }

    .slider__text {
        height: 200px;
        padding: 15px 20px;
    }

    .team__heading {
        padding: 15px 0px;
    }

    .team__single {
        height: 300px;
    }

    .jobs__heading {
        padding: 15px 0px;
    }

    .application__headline {
        padding: 15px 0px;
    }

    .custom-file-upload {
        margin-bottom: 0;
    }

    .hotspot {
        height: 650px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1400px) {
    :root {
        font-size: 15px;
        line-height: 1.4;
    }
}