.hero {
    display: flex;
    width: 100%;
    height: 650px;
    position: relative;
    overflow: hidden;
}

.hero__background {
    position: absolute;
    min-width: 75%;
    height: 650px;
    max-height: 650px;
    z-index: -1;
    top: 0;
    right: 0;
    object-fit: cover;
}

.hero__background--center {
    position: absolute;
    min-width: 100%;
    height: 650px;
    z-index: -1;
    top: 0;
    right: 0;
    object-fit: cover;
}

.hero__content {
    background-color: var(--white);
    width: 100%;
    height: 650px;
    background: linear-gradient(to left, transparent,transparent 50%, white 75%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.hero__content--center {
    background-color: var(--white);
    width: 100%;
    height: 650px;
    background: linear-gradient(to Bottom, transparent,transparent 40%, white 85%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}

.hero__text-wrapper {
    display: flex;
    flex-direction: column;
    width: 80%;
    padding: 100px 0px 120px 0px;
}

.hero__text-wrapper--center {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    padding: 100px 0px 0px 0px;
    position: relative;
    z-index: 1;
}

.hero__heading {
    font-size: var(--size-heading);
    color: var(--black);
    font-weight: bolder;
    width: 45%;
    line-height: 1.3;
}

.hero__heading--center {
    font-size: var(--size-heading);
    color: var(--black);
    font-family: var(--font-heading);
    text-align: center;
    width: 80%;
    padding-top: 0;
    padding-bottom: 0;
}

.hero__text {
    font-size: var(--size-accent);
    font-weight: lighter;
    width: 45%;
}

.hero__text--center {
    font-size: var(--size-accent);
    font-family: var(--font-accent);
    text-align: center;
    color: var(--green-main);
}

.button {
    height: 60px;
    width: 270px;
    border: none;
    border-radius: 100px;
    background-color: var(--green-main);
    color: #E8F1F2;
    cursor: pointer;
    position: relative;
    margin: 25px 0px;
    transition: all .7s ease-in-out;
    display: flex;
}

.button:hover {
    transform: scale(1.05);
}

.button:hover .button__text {
    letter-spacing: 2px;
}

.button__text {
    color: var(--white);
    font-family: var(--font-accent);
    font-size: var(--size-text);
    transition: all .7s ease-in-out;
    margin: auto;
}

.hero__svg {
    position: absolute;
    z-index: 0;
    bottom: 10%;
    right: 25%;
    height: 350px;
}

.hero-left {
    display: flex;
    width: 100%;
    height: 700px;
    position: relative;
}

.hero-left__background--video {
    position: absolute;
    min-width: 75%;
    height: 700px;
    max-height: 700px;
    z-index: -1;
    top: 0;
    right: 0;
    object-fit: cover;
}

.hero-left__content {
    background-color: var(--white);
    width: 100%;
    height: 700px;
    background: linear-gradient(to left, transparent,transparent 50%, white 75%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.hero-left__text-wrapper {
    display: flex;
    flex-direction: column;
    width: 80%;
    padding: 100px 0px 120px 0px;
}

.hero-left__heading {
    font-size: var(--size-heading);
    color: var(--black);
    width: 45%;
    font-family: var(--font-heading);
}

.hero-left__text {
    font-size: var(--size-accent);
    font-family: var(--font-accent);
    width: 45%;
    color: var(--green-main);
}

