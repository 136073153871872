.svg-separator {
    display: flex;
    justify-content: center;
    align-items: center;
}

.svg-separator--scale {
    height: 100px;
}

.flower-background--top {
    position: absolute;
    z-index: -1;
    top: 0px;
    right: -100px;
    height: 350px;
}

.flower-background--top-right {
    position: absolute;
    z-index: -1;
    top: 0px;
    right: -100px;
    height: 350px;
}

.flower-background {
    position: absolute;
    z-index: -1;
    bottom: 220px;
    left: -100px;
    height: 350px;
}

.flower-background--center {
    position: absolute;
    z-index: -1;
    bottom: 50px;
    left: +100px;
    height: 350px;
}

.flower-background--bottom {
    position: absolute;
    z-index: -1;
    bottom: 0px;
    left: -100px;
    height: 350px;
}

.single-headline__heading {
    font-size: var(--size-small-heading);
    color: var(--black);
    font-family: var(--font-heading);
    text-align: center;
}

.single-headline {
    width: 80%;
    margin: auto;
    margin-top: 40px;
}

.scroll-animator {
    height: 300px;
    position: absolute;
    top: calc(50% - 150px);
    right: 7%;
}

.svg-logo__small {
    height: 60px;
}

.teaser__svg--scale {
    height: 120px;
}