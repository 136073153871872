/** animation */
.parallax-left, .parallax-right, .parallax-bottom, .parallax-top {
    transform: translate3d(0px, 0px, 0px);
    opacity: 0;
    transition: all .7s ease-in-out;
}

.parallax-instant--bottom {
    opacity: 0;
    transform: translate3d(0px, 30px, 0px);
    transition: all .7s ease-in-out;
}

.parallax-instant--top {
    opacity: 0;
    transform: translate3d(0px, -100%, 0px);
    transition: all 1.4s ease-in-out;
}

.parallax-background {
    transition: all .7s ease;
}

.sticky {
    background-color: var(--green-light);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    margin-top: 0;
    transition: all .5s ease;
    height: 55px;
}