.header-mobile {
    visibility: hidden;
    position: fixed;
    transition: all .5s ease;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    background-color: var(--white);
}

.header-mobile--active {
    bottom: 0;
    height: auto !important;
}

.header-mobile-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: relative;
    z-index: 1;
    align-items: flex-end;
}

.nav-mobile {
    transform: translateX(+120%);
    transition: .7s all ease-in-out;
    width: 100%;
    height: 0;
}

.header__nav--mobile {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header__list--mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.header__item--mobile {
    padding: 20px;
    text-align: center;
    opacity: 0;
}

.header__item--active {
    opacity: 1;
    transform: translateX(0%);
    visibility: visible;
    transition: all .3s ease-in-out;
}

.header__link--mobile {
    color: var(--green-main);
    font-family: var(--font-accent);
    padding: 10px 40px;
    font-size: var(--size-small-heading);
    position: relative;
}

.header__link--mobile::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 3px;
    background-color: var(--green-light-dark);
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .3s ease-in-out;
}

.header__link--mobile:hover::before {
    transform-origin: left;
    transform: scaleX(1);
}

.nav-mobile-active {
    transform: translateX(0%);
    visibility: visible;
    height: 800px;
    overflow-y: scroll;
}

.logo__link--mobile {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding-bottom: 15px;
    padding-left: 15px;
}

.hamburger {
    display: flex;
}

@keyframes nav-link-fade {
    from {
        opacity: 0;
        transform: translateX(120%);
    }
    to {
        opacity: 1;
        transform: translateX(0%);
    }
}