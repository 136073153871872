.footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--green-light);
}

.footer__wrapper {
    width: 80%;
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.footer__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.footer__links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.footer__link {
    font-family: var(--font-accent);
    color: var(--green-main);
    font-size: var(--size-text);
    margin-bottom: 15px;
    position: relative;
}

.footer__link::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 3px;
    background-color: var(--green-light-dark);
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .3s ease-in-out;
}

.footer__link:hover::before {
    transform-origin: left;
    transform: scaleX(1);
}

.footer__wrapper {
    padding-top: 0;
    padding-bottom: 20px;
    position: relative;
}

.footer__links--full-width {
    width: 100%;
}

.footer__links--full-width .footer__container {
    width: 100%;
}

.footer__links--full-width .footer__container .footer__link {
    text-align: center;
    font-family: var(--font-accent);
    margin-top: 40px;
}

.footer__content {
    flex-wrap: wrap;
}

.footer__logos {
    width: 100%;
    margin: 50px 0px 20px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.footer__logo {
    margin-left: 40px;
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
    display: flex;
}

.footer__svg {
    width: 265px;
}

.footer__logos__external {
    display: flex;
    flex-direction: row;

}