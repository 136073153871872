.team {
    display: flex;
    flex-direction: column;
    padding: 100px 0px;
    width: 100%;
}

.team__wrapper {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.team__heading {
    font-size: var(--size-small-heading);
    font-family: var(--font-heading);
    text-align: center;
    padding: 25px 0px;
}

.team__media {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.team__single {
    width: 24%;
    height: 350px;
    position: relative;
    padding: 10px 0px;

}

.team__img {
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}

.team__content {
    position: absolute;
    width: 100%;
    background-color: transparent;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 20px;
    z-index: -1;
    opacity: 0;
}

.team__content--active {
    z-index: 1;
    background-color: white;
    opacity: 1;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.team__svg {
    height: 25px;
    width: 40px;
    margin-right: 10px;
}

.team__name {
    font-family: var(--font-heading);
    color: var(--green-main);
    font-size: var(--size-text);
}

.team__position {
    font-family: var(--font-accent);
    color: var(--green-main);
}

.team__person {
    display: flex;
    flex-direction: column;
}

.team__single:hover > .team__content {
    z-index: 1;
    background-color: white;
    opacity: 1;
    -webkit-transition: all .8s ease-in-out;
    -moz-transition: all .8s ease-in-out;
    -ms-transition: all .8s ease-in-out;
    -o-transition: all .8s ease-in-out;
    transition: all .8s ease-in-out;
}