.news {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    padding: 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
}

.news > .swiper-pagination-all {
    display: none;
    visibility: hidden;
}

.news__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    gap: 20px;
}

.news__media {
    min-width: 47%;
    max-width: 47%;
    height: 400px;
    max-height: 400px;
    object-fit: cover;
}

.news__media__image {
    object-fit: cover;
}

.news__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 47%;
}

.news__category {
    color: var(--green-main);
    font-family: var(--font-accent);
    font-size: var(--size-accent);
}

.news__heading {
    font-size: var(--size-small-heading);
    font-family: var(--font-heading);
}

.news__text {
    font-family: var(--font-text);
    padding: 20px 0px;
    font-size: var(--size-text);
}

.link {
    display: block;
    font-size: var(--size-accent);
    font-family: var(--font-accent);
    color: var(--green-main);
    background:
            linear-gradient(
                    to right,
                    var(--green-light),
                    var(--green-light)
            ),
            linear-gradient(
                    to right,
                    var(--green-light),
                    var(--green-light-dark),
                    var(--green-main)
            );
    background-size: 100% 3px, 0 3px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 400ms;
}

.link:hover {
    background-size: 0 3px, 100% 3px;
}

.news__button--left {
    margin: auto;
}

.news__button--right {
    margin: auto;
}