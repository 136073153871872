.shop {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 50px 0px;
}

.shop__heading {
    font-size: var(--size-small-heading);
    font-family: var(--font-heading);
    text-align: center;
    padding: 25px 0px;
}

.shop__wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.shop__content {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    padding-bottom: 40px;
    overflow: hidden;
}

.shop__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 500px;
    width: 23%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: relative;
}

.shop__img {
    min-width: 100%;
    max-width: 100%;
    height: 255px;
    object-fit: cover;
}

.shop__text {
    height: 150px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--white);
    padding: 20px 15px;
}

.shop__text-small {
    font-family: var(--font-accent);
    font-size: var(--size-extra-small);
}

.shop__name {
    font-family: var(--font-heading);
    font-size: var(--size-small--accent);
}

.shop__info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: auto;
    flex-wrap: wrap;
}

.shop__status {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.shop__status--active {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: var(--green-main);
}

.shop__status-text {
    font-family: var(--font-accent);
    margin-left: 5px;
    font-size: var(--size-extra-small);
}

.shop__oldprice {
    font-family: var(--font-accent);
    font-size: var(--size-extra-small);
    color: grey;
    text-decoration-line: line-through;
}

.shop__price {
    font-family: var(--font-heading);
    font-size: var(--size-extra-small);
}

.shop__linkarea {
    width: 100%;
    height: 60px;
    background-color: var(--green-light);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .3s ease-in-out;
}

.shop__linkarea:hover {
    background-color: var(--green-light-dark);
}

.shop__linkarea:hover .shop__link {
    background-size: 0 3px, 100% 3px;
    letter-spacing: 10px;
    color: var(--green-main);
    text-align: center;
}

.shop__link {
    letter-spacing: 10px;
    color: var(--green-main);
    text-align: center;
    font-family: var(--font-accent);
    background:
            linear-gradient(
                    to right,
                    var(--green-light-dark),
                    var(--green-light-dark)
            ),
            linear-gradient(
                    to right,
                    var(--green-light),
                    var(--green-light-dark),
                    var(--green-main)
            );
    background-size: 100% 3px, 0 3px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 400ms;
}

.shop__icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 65px;
}

