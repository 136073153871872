.header {
    background-color: var(--white);
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: relative;
    z-index: 1;
    transition: all .3s ease-in-out;
}

.logo {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0px 40px 12px 0px;
    transition: all .7s ease-in-out;
}

.logo__link {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo__svg {
    width: 170px;
    pointer-events: none;

}

.header__nav {
    height: 100%;
    padding: 0px 0px 0px 40px;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: flex-end;
}

.header__list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 20px;
}

.header__item {
}

.header__link {
    color: var(--green-main);
    padding: 5px 0px;
    font-size: var(--size-text);
    position: relative;
    font-family: var(--font-text);
}

.header__link::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 3px;
    background-color: var(--green-light-dark);
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .3s ease-in-out;
}

.header__link--active::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 3px;
    background-color: var(--green-light-dark);
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .3s ease-in-out;
}

.header__link:hover::before {
    transform-origin: left;
    transform: scaleX(1);
}

.header__link--active::before {
    transform-origin: left;
    transform: scaleX(1);
}

.header__social {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.header__social-link {
    width: 100%;
    height: 100%;
    display: block;
    padding: 0px 0px 0px 10px;
    transition: all .3s ease-in-out;
}

.header__social-link:hover {
    transform: scale(1.1);
}

.header__svg {
    pointer-events: none;
    width: 22px;
    height: 22px;
}


.header__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.header__top {
    width: 100%;
    height: 40px;
    min-height: 40px;
    background-color: var(--green-light);
    transition: all .3s ease-in-out;
}

.header__top__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
}

.header__data__wrapper {
    display: flex;
    flex-direction: row;
    padding: 0px 40px 0px 0px;
    height: 100%;
}

.header__data {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-right: 20px;
}

.header__opening {
    white-space: nowrap;
    text-decoration: none;
    color: var(--green-main);
    font-family: var(--font-text);
    font-size: var(--size-text);
    margin-left: 5px;
}

.header__normal {
    background-color: var(--white);
    width: 100%;
    min-height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: relative;
    z-index: 1;
    transition: all .3s ease-in-out;
}

.header__normal:hover {
    height: 80px !important;
    min-height: 80px !important;
}

.header__normal__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
}

.header__social-link {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sticky {
    height: 55px;
}

.sticky .header__normal {
    height: 55px;
    min-height: 55px;
    background-color: var(--green-light);
}

.sticky .header__top {
    height: 0;
    visibility: hidden;
    min-height: 0px;
}

.sticky .header__list, .sticky .header__nav {
    align-items: center;
    padding-bottom: 0;
}

.sticky .half-circle {
    height: 55px;
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    margin-left: 0;
}

.sticky .logo__link {
    height: 100%;
    padding-bottom: 0;
}

.sticky .logo {
    padding-bottom: 0;
}