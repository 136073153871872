.rating {
    width: 100%;
}

.rating__single {
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    padding-bottom: 0;
}

.rating__text {
    font-size: var(--size-accent);
    font-family: var(--font-italic);
    color: var(--green-main);
    text-align: center;
}

.rating__subtext {
    font-size: var(--size-accent);
    text-align: center;
    padding: 20px;
    padding-bottom: 0px;
    font-family: var(--font-accent);
}

.rating__slider {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 80%;
    padding: 0px 0px 50px 0px;
}

.slider__single {
    width: 40px;
    height: 40px;
    background-color: var(--green-light-dark);
    border-radius: 100%;
    margin: 7px;
    display: flex;
    justify-content: center;
}

.slider__active {
    width: 12px;
    height: 12px;
    background-color: var(--white);
    border-radius: 100%;
    margin: auto;
}