.contact {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding: 50px 0px;
}

.contact__heading {
    font-size: var(--size-small-heading);
    font-family: var(--font-heading);
    text-align: center;
    padding: 25px 0px 50px 0px;
}

.contact__wrapper {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 500px;
}

.contact__media {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.address {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}

.address__needle {
    height: 75px;
}

.address__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 80%;
    padding: 0px 40px;
}

.address__company {
    font-size: var(--size-text);
    font-family: var(--font-heading);
    padding-bottom: 5px;
}

.address__text {
    font-size: var(--size-text);
    font-family: var(--font-text);
}

.contact__map {
    min-width: 100%;
    max-width: 100%;
    height: 300px;
    object-fit: cover;
}

.contact__map--img {
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}

.companies {
    width: 55%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.companies__single {
    width: 47%;
    height: 100%;
    border-radius: 10px;
    border: 5px solid var(--green-light-dark);
    position: relative;
    display: flex;
    flex-direction: column;
    transition: all .5s ease-in-out;
}

.companies__single:hover {
    background-color: var(--green-light);
}

.companies__icon-wrapper {
    width: 120px;
    height: 120px;
    position: absolute;
    top: -60px;
    left: calc(50% - 60px);
    background-color: white;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.companies__icon {
    min-width: 85px;
    max-width: 85px;
    height: 85px;
    border-radius: 100%;
    object-fit: cover;
    margin: auto;
}

.companies__heading {
    width: 100%;
    font-family: var(--font-heading);
    font-size: var(--size-text);
    text-align: center;
    padding: 60px 20px 20px 20px;
}

.companies__opening-hours {
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    font-size: var(--size-text);
}

.companies__opening-days {
    font-family: var(--font-heading);
    padding: 10px 0px 0px 0px;
}

.companies__opening-times {
    font-family: var(--font-text);
    font-size: var(--size-text);
}

.companies__contacts {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    margin-top: auto;
}

.companies__single-contact {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px 0px;
}

.companies__contact-icon {
    width: 25px;
    height: 25px;
    margin: auto;
    margin-right: 20px;
    margin-left: 0px;
}

.companies__contact-information {
    color: var(--green-main);
    font-size: var(--size-text);
    font-family: var(--font-text);
    background:
            linear-gradient(
                    to right,
                    var(--green-light-dark),
                    var(--green-light-dark)
            ),
            linear-gradient(
                    to right,
                    var(--green-light),
                    var(--green-light-dark),
                    var(--green-main)
            );
    background-size: 100% 3px, 0 3px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 400ms;
}

.companies__contact-information:hover {
    background-size: 0 3px, 100% 3px;
}

.contact__svg-logo {
    height: 50px;
    width: 50px;
    position: absolute;
    border-radius: 100%;
    background-color: white;
    padding: 10px;
    border: 3px solid var(--green-main);
}

.contact__map-wrapper {
    position: relative;
    height: 300px;
}

