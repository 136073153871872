.cookie {
    position: fixed;
    z-index: 100;
    bottom: 0;
    background-color: var(--green-light);
    padding: 20px 0px;
    width: 100%;
    display: none;
    visibility: hidden;
}

.cookie--active {
    display: flex;
    visibility: visible;
}

.cookie__content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.cookie__heading {
    font-family: var(--font-heading);
    font-size: 2rem;
}

.cookie__text {
    font-family: var(--font-text);
    margin-top: 10px;
}

.cookie__link-wrapper {
    display: flex;
    flex-direction: row;
    font-family: var(--font-text);
    margin-top: 20px;
}

.cookie__link {
    padding: 15px;
    background-color: var(--green-light-dark);
    margin-right: 20px;
    min-width: 220px;
    text-align: center;
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.cookie__link:hover {
    text-decoration: underline;
}

.cookie__accept {
    background-color: var(--green-main);
    color: white;
}

@media only screen and (max-width: 600px) {
    .cookie__link {
        min-width: 100%;
        margin-top: 10px;
    }

    .cookie__link-wrapper {
        flex-direction: column;
    }
}