@import "fonts.css";
@import "main.css";
@import "header.css";
@import "hamburgers.css";
@import "hero.css";
@import "news.css";
@import "components.css";
@import "teaser.css";
@import "headline.css";
@import "cards.css";
@import "cta.css";
@import "rating.css";
@import "impression.css";
@import "contact.css";
@import "slider.css";
@import "hero-content.css";
@import "team.css";
@import "jobs.css";
@import "form.css";
@import "shop.css";
@import "content.css";
@import "footer.css";
@import "header-mobile.css";
@import "animate.css";
@import "hotspot.css";
@import "slider-js.css";
@import "svg.css";
@import "swiper.css";
@import "frc.css";
@import "cookie.css";
@import "mobile.css";