.teaser {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px;
}

.teaser__media {
    min-width: 47%;
    max-width: 47%;
    height: 350px;
    max-height: 350px;
    object-fit: cover;
}

.teaser__media--cover {
    object-fit: cover;
}

.teaser__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    padding-top: 25px;
    padding-bottom: 25px;
}

.teaser__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 47%;
    align-items: flex-start;
    gap: 10px;
}

.teaser__heading {
    font-size: var(--size-small-heading);
    font-family: var(--font-heading);
}

.teaser__text {
    font-family: var(--font-text);
    padding: 10px 0px;
    font-size: var(--size-text);
}

.teaser__svg {
    margin: auto;
}