.headline {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
}

.headline__subheadline {
    font-size: var(--size-accent);
    font-family: var(--font-accent);
    color: var(--green-main);
    text-align: center;
}

.headline__headline {
    font-size: var(--size-small-heading);
    color: var(--black);
    font-family: var(--font-heading);
    text-align: center;
}