.hero-content {
    display: flex;
    width: 100%;
    height: 800px;
    position: relative;
}

.hero-content__wrapper {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 80px;
    padding-bottom: 80px;
}

.hero-content__content {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
}

.hero-content__small-heading {
    font-size: var(--size-accent);
    font-family: var(--font-accent);
    color: var(--green-main);
    margin-top: 100px;
}

.hero-content__headline {
    font-size: var(--size-heading);
    font-family: var(--font-heading);
    position: relative;
    z-index: 1;
}

.hero-content__text {
    font-family: var(--font-text);
    font-size: var(--size-text);
    padding-top: 10px;
}

.hero-content__slider {
    width: 55%;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    overflow: hidden;
}

.hero-content__slider > .swiper-pagination-all {
    padding-bottom: 0px;
    padding-top: 25px;
}

.hero-content__slider-wrapper {
    height: 85% !important;
}

.hero-content__media {
    min-width: 100%;
    max-width: 100%;
    height: 90%;
    object-fit: cover;
}

.hero-content__media--active {
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-content__bubbles {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 30px;
}

.hero-content__single-bubble {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: var(--green-light);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0px 5px;
}

.hero-content__single-bubble--active {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: var(--green-main);
}

.hero-content__button {
    width: 350px;
    height: 350px;
    border-radius: 100%;
    border: 3px solid var(--green-light-dark);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 100px;
    z-index: 0;
    transition: all .3s ease-in-out;
}

.hero-content__link {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.hero-content__button:hover .hero-content__link-text {
    font-size: var(--size-small-subheading);
}

.hero-content__button:hover {
    border: 7px solid var(--green-light-dark);
}

.hero-content__button:active .hero-content__flower {
    width: 500px;
}

.hero-content__button:hover .hero-content__flower {
    width: 400px;
}

.hero-content__link-text {
    letter-spacing: 10px;
    font-family: var(--font-accent);
    color: var(--green-main);
    border-bottom: 4px solid var(--green-main);
    transition: all .5s ease-in-out;
}

.hero-content__flower {
    position: absolute;
    z-index: -1;
    width: 200px;
    transition: all .7s ease-in-out;
}




