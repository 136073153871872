.frc-captcha {
    background-color: transparent !important;
    margin-bottom: 20px !important;
    min-width: auto !important;
    max-width: none !important;
    border: 1px solid var(--green-light-dark) !important;
}

.frc-icon {
    fill: var(--green-main) !important;
    stroke: var(--green-main) !important;
}

.frc-container {
    min-height: 40px !important;
}