/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

:root {
    color: black;
    background-color: white;
    font-size: 16px;
    line-height: 1.4;
}
body {
    line-height: 1.4;
    overflow-x: hidden;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

*, *:after, *:before {
    box-sizing: border-box;
}

.maxwidthcontainer {
    max-width: 80em;
    margin: 0 auto;
    padding: 20px;
}

a:link, a:visited,  a:hover, a:active
{
    text-decoration: none;
}

html {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.overflow {
    overflow-x: hidden;
    position: relative;
}

:root {
    --green-main: #007C45;
    --green-light: #DAF2E4;
    --green-light-dark: #C5E6D5;
    --white: white;
    --black: black;
    --font: 'Helvetica Neue', Arial, sans-serif;
    --font-heading: 'Open Sans 700', Arial, sans-serif;
    --font-text: 'PT Sans', Arial, sans-serif;
    --font-accent: 'Roboto 300', Arial, sans-serif;
    --font-italic: 'PT Sans Italic', Arial, sans-serif;
    --size-heading: 3.3rem;
    --size-accent: 1.7rem;
    --size-small-heading: 2.5rem;
    --size-small-subheading: 1.5rem;
    --size-text: 1rem;
    --size-small: 1rem;
    --size-extra-small: .9rem;
    --size-small--accent: 1.2rem;
}

strong {
    font-family: var(--font-heading);
}

img {
    background-color: var(--green-light);
}


