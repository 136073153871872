.slider {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 50px 0px;
}

.slider__heading {
    font-size: var(--size-small-heading);
    font-family: var(--font-heading);
    text-align: center;
    padding: 25px 0px;
}

.slider__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.slider__content {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    padding-bottom: 20px;
    overflow: hidden;
}

.slider__item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 500px;
    width: 30%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: relative;
}

.slider__img {
    min-width: 100%;
    max-width: 100%;
    height: 190px;
    object-fit: cover;
}

.slider__text {
    height: 220px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--white);
    padding: 20px;
}

.slider__text-small {
    padding-top: 5px;
    font-family: var(--font-text);
    font-size: var(--size-text);
}

.slider__name {
    font-family: var(--font-heading);
    font-size: var(--size-small-subheading);
}

.slider__info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: auto;
}

.slider__status {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.slider__status--active {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    background-color: var(--green-main);
}

.slider__status-text {
    font-weight: lighter;
    margin-left: 10px;
}

.slider__oldprice {
    font-weight: lighter;
    color: grey;
    text-decoration-line: line-through;
}

.slider__price {
    font-weight: bolder;
}

.slider__linkarea {
    width: 100%;
    height: 70px;
    background-color: var(--green-light);
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider__link {
    letter-spacing: 10px;
    color: var(--green-main);
    text-align: center;
    font-family: var(--font-accent);
    font-size: var(--size-text);
    background:
        linear-gradient(
                to right,
                var(--green-light-dark),
                var(--green-light-dark)
        ),
        linear-gradient(
                to right,
                var(--green-light),
                var(--green-light-dark),
                var(--green-main)
        );
    background-size: 100% 5px, 0 5px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 400ms;
}

.slider__link:hover {
    background-size: 0 5px, 100% 5px;
}
.slider__icon {
    position: absolute;
    top: 10px;
    right: 10px;
}

