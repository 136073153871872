.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.content__text-media {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 40px;
}

.content__content {
    width: 47%;
    display: flex;
    flex-direction: column;
}

.content__content-text {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.content__content-media {
    min-width: 100%;
    max-width: 100%;
    object-fit: cover;
}

.content__text {
    font-family: var(--font-text);
    font-size: var(--size-text);
}

.content__media {
    width: 47%;
    height: 350px;
}

.content__img {
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}

.content__fullwidth {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    height: auto;
    max-height: 500px;
    object-fit: cover;
}

.content__preheadline {
    color: var(--green-main);
    font-family: var(--font-accent);
    font-size: var(--size-text);
}

.content__headline {
    font-family: var(--font-heading);
    font-size: var(--size-accent);
    padding-bottom: 20px;
}

.content__headline--center {
    font-weight: bolder;
    font-size: var(--size-accent);
    padding-bottom: 20px;
    text-align: center;
}

.content__headline--big {
    font-family: var(--font-heading);
    font-size: var(--size-accent);
    margin-bottom: 20px;
    color: var(--green-main);
    border-bottom: 5px solid var(--green-light);
}

.content__link {
    color: var(--green-main);
    letter-spacing: 10px;
    font-family: var(--font-accent);
    width: fit-content;
    padding-top: 20px;
    background:
            linear-gradient(
                    to right,
                    var(--green-light),
                    var(--green-light)
            ),
            linear-gradient(
                    to right,
                    var(--green-light),
                    var(--green-light-dark),
                    var(--green-main)
            );
    background-size: 100% 5px, 0 5px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 400ms;
}

.content__link:hover {
    background-size: 0 5px, 100% 5px;
}

.pimcore_editable_video {
    height: 100%;
}