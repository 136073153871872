.cta {
    width: 100%;
    background-color: var(--green-light);
}

.cta__content {
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 80%;
    position: relative;
}

.cta__content-all {
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 60%;
    position: relative;
}

.cta__text {
    font-family: var(--font-accent);
    font-size: var(--size-accent);
    color: var(--green-main);
}

.cta__heading {
    font-size: var(--size-small-heading);
    font-family: var(--font-heading);
    color: var(--black);
}

.cta__wrapper {
    display: flex;
    flex-direction: row;
    padding: 20px 0px;
}

.cta__input {
    height: 60px;
    width: 100%;
    border: none;
    color: var(--green-main);
    font-size: var(--size-text);
    font-family: var(--font-accent);
    padding-inline: 30px;
    border-radius: 3px;
    margin-right: 20px;
}

input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--green-main);
    opacity: 1; /* Firefox */
    font-weight: lighter;
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--green-main);
    font-weight: lighter;
}

input::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--green-main);
    font-weight: lighter;
}

.cta__submit {
    margin: 0 !important;
    font-family: var(--font-accent);
    height: 60px !important;
    width: 100% !important;
    border: none !important;
    color: var(--white) !important;
    font-size: 1.5rem !important;
    padding-inline: 30px !important;
    border-radius: 3px !important;
    margin-right: 20px !important;
    background-color: var(--green-main) !important;
    font-weight: lighter !important;
    cursor: pointer !important;
    transition: all .7s ease-in-out !important;
    z-index: 1 !important;
}

.cta__submit:hover {
    transform: scale(1.05);
    background-color: var(--green-main) !important;
    letter-spacing: 2px;
}

.cta__svg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 200px;
}