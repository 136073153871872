.jobs {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 100px 0px;
}

.jobs__heading {
    font-size: var(--size-small-heading);
    font-family: var(--font-heading);
    text-align: center;
    padding: 25px 0px;
}

.jobs__wrapper {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.jobs__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.job {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 40px 50px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 4px;
    margin-top: 20px;
    background-color: var(--white);
}

.job__content {
    display: flex;
    flex-direction: column;
    width: 60%;
}

.job__title {
    font-size: var(--size-small-subheading);
    font-family: var(--font-heading);
}

.job__description {
    visibility: visible;
    opacity: 1;
    height: auto;
    padding: 30px 0px;
    transition: all .7s ease-in-out;
    font-family: var(--font-text);
    font-size: var(--size-text);
}

.job__description > p {
    padding: 5px 0px;
}

.job__action {
    cursor: pointer;
}

.job__action--expanded {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.job__action--unexpanded {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 20px;
}

.job__text-action {
    letter-spacing: 10px;
    color: var(--green-main);
    margin-right: 10px;
    position: relative;
    font-family: var(--font-accent);
}

.job__text-action::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: var(--green-light-dark);
    bottom: -3px;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .3s ease-in-out;
}

.job__action:hover .job__text-action::before {
    transform-origin: left;
    transform: scaleX(1);
}

.job__action:hover .job__arrow {
    background-color: var(--green-light-dark);
    border-radius: 100%;
}

.job__arrow--top {
    transform: rotate(-90deg);
    height: 50px;
    transition: all .7s ease-in-out;
}

.job__arrow--right {
    height: 50px;
    transition: all .7s ease-in-out;
}

.job__link {
    display: flex;
    justify-content: center;
    align-items: center;
}

.job__link-text {
    letter-spacing: 10px;
    font-family: var(--font-accent);
    color: var(--green-main);
    background:
            linear-gradient(
                    to right,
                    var(--green-light),
                    var(--green-light)
            ),
            linear-gradient(
                    to right,
                    var(--green-light),
                    var(--green-light-dark),
                    var(--green-main)
            );
    background-size: 100% 5px, 0 5px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 400ms;
}

.job__link:hover .job__link-text {
    background-size: 0 5px, 100% 5px;
}

.job__description--inactive {
    padding: 0;
    visibility: hidden;
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: all .7s ease-in-out;
}