/* pt-sans-regular - latin */
@font-face {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/pt-sans-v17-latin/pt-sans-v17-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/pt-sans-v17-latin/pt-sans-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/pt-sans-v17-latin/pt-sans-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/pt-sans-v17-latin/pt-sans-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/pt-sans-v17-latin/pt-sans-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/pt-sans-v17-latin/pt-sans-v17-latin-regular.svg#PTSans') format('svg'); /* Legacy iOS */
}
/* pt-sans-italic - latin */
@font-face {
    font-family: 'PT Sans Italic';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/pt-sans-v17-latin/pt-sans-v17-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/pt-sans-v17-latin/pt-sans-v17-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/pt-sans-v17-latin/pt-sans-v17-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/pt-sans-v17-latin/pt-sans-v17-latin-italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/pt-sans-v17-latin/pt-sans-v17-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/pt-sans-v17-latin/pt-sans-v17-latin-italic.svg#PTSans') format('svg'); /* Legacy iOS */
}

/* open-sans-regular - latin */
@font-face {
    font-family: 'Open Sans regular';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/open-sans-v29-latin/open-sans-v29-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/open-sans-v29-latin/open-sans-v29-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/open-sans-v29-latin/open-sans-v29-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/open-sans-v29-latin/open-sans-v29-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/open-sans-v29-latin/open-sans-v29-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/open-sans-v29-latin/open-sans-v29-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
    font-family: 'Open Sans 700';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/open-sans-v29-latin/open-sans-v29-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/open-sans-v29-latin/open-sans-v29-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/open-sans-v29-latin/open-sans-v29-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/open-sans-v29-latin/open-sans-v29-latin-700.woff') format('woff'), /* Modern Browsers */
    url('../fonts/open-sans-v29-latin/open-sans-v29-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/open-sans-v29-latin/open-sans-v29-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-800 - latin */
@font-face {
    font-family: 'Open Sans 800';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/open-sans-v29-latin/open-sans-v29-latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/open-sans-v29-latin/open-sans-v29-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/open-sans-v29-latin/open-sans-v29-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/open-sans-v29-latin/open-sans-v29-latin-800.woff') format('woff'), /* Modern Browsers */
    url('../fonts/open-sans-v29-latin/open-sans-v29-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/open-sans-v29-latin/open-sans-v29-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* roboto-300 - latin */
@font-face {
    font-family: 'Roboto 300';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/roboto/roboto-v30-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/roboto/roboto-v30-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/roboto/roboto-v30-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/roboto/roboto-v30-latin-300.woff') format('woff'), /* Modern Browsers */
    url('../fonts/roboto/roboto-v30-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/roboto/roboto-v30-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto 400';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/roboto/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/roboto/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/roboto/roboto-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/roboto/roboto-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/roboto/roboto-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/roboto/roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}