.inactive {
    display: none;
    visibility: hidden;
}

.news__swiper {
    width: 100%;
}

.swiper2 {
    width: 100%;
}

.swiper-slide {
    width: 100%;
}

.swiper-wrapper {
    width: 100%;
}

.swiper-button-prev {
    left: 5% !important;
}

.swiper-button-next {
    right: 5% !important;
}

.swiper-button-next, .swiper-button-prev {
    color: var(--green-main) !important;
    border-radius: 100%;
    border: 1px solid var(--green-light);
    padding: 30px;
    transition: all .5s ease;
}

.swiper-button-next:hover, .swiper-button-prev:hover {
    transform: scale(1.3);
    border: 5px solid var(--green-light);
}

.swiper-button-next:active, .swiper-button-prev:active {
    transform: scale(1.3);
    background-color: var(--green-light);
}

.swiper-button-next2, .swiper-button-prev2 {
    top:60% !important;
}

.swiper-pagination3, .swiper-pagination5, .swiper-pagination6, .swiper-pagination7, .swiper-pagination-all {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 80%;
    padding: 0px 0px 50px 0px;
}

.swiper-pagination5, .swiper-pagination6, .swiper-pagination7, .swiper-pagination-all {
    padding-top: 25px;
}

.swiper-pagination-bullet {
    width: 40px !important;
    height: 40px !important;
    background-color: var(--green-light-dark) !important;
    border-radius: 100% !important;
    margin: 7px !important;
    display: flex !important;
    justify-content: center !important;
    opacity: 1 !important;
}

.swiper-pagination-bullet-active {
    background-color: var(--green-main) !important;
    border-radius: 100%;
    border: 14px solid var(--green-light-dark);
    margin: auto;
}