.cards {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px 0px 50px 0px;
}

.cards__wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 50px 0px;
}

.card {
    border-radius: 20px;
    border: 2px solid var(--green-light-dark);
    width: min(300px, 23%);
    height: 380px;
    position: relative;
    margin: 0px 20px;
}

.card:hover {
    background-color: var(--green-light);
}

.card__media {
    height: 100px;
    width: 100px;
    background-color: var(--white);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -50px;
    left: 33%;
}

.card__img {
    min-width: 80px;
    max-width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    display: inline-block;
}

.card__heading {
    font-size: var(--size-small-subheading);
    font-family: var(--font-heading);
    padding: 55px 10px 0px 10px;
    text-align: center;
}

.card__text {
    font-size: var(--size-text);
    font-family: var(--font-text);
    padding: 20px;
}
