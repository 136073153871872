.hotspot {
    width: 100%;
    height: 700px;
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px;
}

.hotspot__img {
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
}

.hotspot-header {
    padding: 25px 40px;
    background-color: var(--green-light);
    border-radius: 4px;
    width: 40%;
    position: absolute;
    right: 10%;
    top: -40px;
}

.hotspot-header__heading-small {
    color: var(--green-main);
    font-family: var(--font-accent);
    font-size: var(--size-text);
}

.hotspot-header__heading-big {
    font-family: var(--font-heading);
    font-size: var(--size-accent);
}

.hotspot-header__text {
    font-family: var(--font-text);
    font-size: var(--size-text);
    padding-top: 10px;
}

.hotspot__svg {
    height: 60px;
    width: 60px;
    border-radius: 100%;
    background-color: white;
    padding: 10px;
    cursor: pointer;
}

.hotspot__single {
    display: flex;
    width: 450px;
    height: auto;
    align-items: flex-start;
    justify-content: space-between;
    position: absolute;
    top: 50px;
    left: 20px;
}

.hotspot__content {
    width: 85%;
    background-color: var(--green-light);
    padding: 25px;
    opacity: 0;
    height: auto;
    flex-direction: column;
    transition: all .7s ease-in-out;
    border-radius: 3px;
}

.hotspot__single--bottom {
    bottom: 50px;
    left: 55%;
    top: auto;
}

.hotspot__content--active {
    opacity: 1;
    height: auto;
}

.hotspot__heading {
    font-family: var(--font-heading);
    font-size: var(--size-accent);
}

.hotspot__text {
    font-family: var(--font-text);
    font-size: var(--size-text);
    padding-top: 10px;
    padding-bottom: 10px;
}

.hotspot__link {
    color: var(--green-main);
    letter-spacing: 10px;
    font-family: var(--font-accent);
    width: fit-content;
    padding-top: 20px;
    background:
            linear-gradient(
                    to right,
                    var(--green-light-dark),
                    var(--green-light-dark)
            ),
            linear-gradient(
                    to right,
                    var(--green-light),
                    var(--green-light-dark),
                    var(--green-main)
            );
    background-size: 100% 5px, 0 5px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 700ms;
}

.hotspot__link:hover {
    background-size: 0 5px, 100% 5px;
}

.hotspot__single:hover > .hotspot__content {
    opacity: 1;
    height: auto;
}

.hotspot__content--mobile {
    display: none;
    visibility: hidden;
    width: 85%;
    background-color: var(--green-light);
    padding: 25px;
    flex-direction: column;
    transition: all .7s ease-in-out;
    border-radius: 3px;
}

.hotspot__content--mobile-active {
    display: flex;
    visibility: visible;
}

.hotspot__text--mobile {
    font-family: var(--font-text);
    font-size: var(--size-text);
    padding-top: 10px;
    padding-bottom: 10px;
}

.hotspot__svg {
    animation-name: keyframes1;
    animation-iteration-count: infinite;
    transform-origin: 50% 10%;
}

@keyframes keyframes1 {
    0% {
        transform: rotate(-1deg);
        animation-timing-function: ease-in;
    }

    50% {
        transform: rotate(1.5deg);
        animation-timing-function: ease-out;
    }
}

@keyframes keyframes2 {
    0% {
        transform: rotate(1deg);
        animation-timing-function: ease-in;
    }

    50% {
        transform: rotate(-1.5deg);
        animation-timing-function: ease-out;
    }
}